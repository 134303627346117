// SnackBarComponent.jsx
import React from "react"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"

const SnackBarComponent = ({ message, open, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000} // Duración de 6 segundos antes de que se cierre automáticamente
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }} // Posición en la esquina inferior derecha
    >
      <MuiAlert
        onClose={onClose}
        severity="success"
        elevation={6}
        variant="filled"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default SnackBarComponent
