import { call, put, takeEvery } from "redux-saga/effects"
import {
  LOCK_JOB_REQUEST,
  UNLOCK_JOB_REQUEST,
  CHECK_AVAILABLE_JOB,
} from "./actionTypes"
import {
  lockJobSuccess,
  lockJobError,
  unlockJobSuccess,
  unlockJobError,
  checkAvailableJobSuccess,
  checkAvailableJobError,
} from "./actions"
import api from "../api" // Asegúrate de que la ruta es correcta.

const userData = JSON.parse(localStorage?.getItem("userData"))
const userName = userData?.userName
console.log("🚀 ~ userName:", userName)

const BASE_URL = process.env.REACT_APP_NEST_API

function* lockJob({ payload: { jobId } }) {
  try {
    const response = yield call(api.post, BASE_URL, `/job-list/lock/${jobId}`, {
      userId: userName,
    })
    yield put(lockJobSuccess(response))
  } catch (error) {
    // Utilizando encadenamiento opcional para extraer el mensaje de error
    const errorMessage = error?.response?.data?.message || error.message

    // Envía el error al reducer
    yield put(
      lockJobError({
        message: errorMessage,
        code: error?.response?.status || 500,
      })
    )
  }
}

function* unlockJob({ payload: { jobId } }) {
  try {
    const response = yield call(
      api.post,
      BASE_URL,
      `/job-list/unlock/${jobId}`,
      {
        userId: userName,
      }
    )
    yield put(unlockJobSuccess(response.data))
  } catch (error) {
    yield put(unlockJobError(error.message))
  }
}

function* checkAvailableJob({ payload: { jobId } }) {
  try {
    const response = yield call(
      api.post,
      BASE_URL,
      `/job-list/check-access/${jobId}`,
      {
        userId: userName,
      }
    )
    yield put(checkAvailableJobSuccess(response.data))
  } catch (error) {
    yield put(checkAvailableJobError(error.message))
  }
}

export function* leaseJobsSaga() {
  yield takeEvery(LOCK_JOB_REQUEST, lockJob)
  yield takeEvery(UNLOCK_JOB_REQUEST, unlockJob)
  yield takeEvery(CHECK_AVAILABLE_JOB, checkAvailableJob)
}

export default leaseJobsSaga
